<template>
    <div>
        <a-input-search class="serach_input" placeholder="搜索地点" v-model="keyword"></a-input-search>
        <baidu-map
        :center="center"
		:scroll-wheel-zoom="true"
            :zoom="zoom">
            <bm-view class="map"></bm-view>
            <bm-local-search :keyword="keyword" @infohtmlset="get_markersset" :pageCapacity="2" :auto-viewport="true" :location="location"></bm-local-search>
        </baidu-map>
    </div>
</template>

<script>
import Search from 'vue-baidu-map/components/search/LocalSearch';
export default {
    components:{
        Search  
    },
    name: "simple-map",
    props: {},
    data: () => ({
        center: { lng: 115.86631, lat: 28.695285},
        zoom: 13,
        keyword:'',
        location: '南昌',
    }),
    methods: {
        get_markersset(e){
            this.$emit('get_title',e)
        },
        // handler({ BMap, map }) {
        //     var point = new BMap.Point(115.86631, 28.695285);
        //     map.centerAndZoom(point, 13);
        //     var marker = new BMap.Marker(point); // 创建标注
        //     map.addOverlay(marker); // 将标注添加到地图中
        //     var circle = new BMap.Circle(point, 200, {
        //         strokeColor: "#007aff",
        //         strokeWeight: 2,
        //         strokeOpacity: 0.6,
        //         fillOpacity: 0.2,
        //         Color: "#007aff",
        //         fillColor: "#007aff"
        //     });
        //     map.addOverlay(circle);
        // },
        // getClickInfo(e) {
        //     this.center.lng = e.point.lng;
        //     this.center.lat = e.point.lat;
        // }
    }
};
</script>

<style scoped>
.map {
    width: 100%;
    height: 250px;
}
.map .search {
    margin-bottom: 65px;
}
  .serach_input {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 99;
    max-width: 200px;
  }
</style>
